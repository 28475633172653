<template>

<app-content :loading="is.loading">

	<app-content-head back="Schedule.Event" title="Manage event" />

	<app-content-body v-if="!is.loading">

		<app-content-box icon="settings" title="About">
			
			<app-input-toggle label="Official" v-model="model.official" :validation="$v.model.official" v-if="$isModerator" />
			<app-input-toggle label="Enable Clashing" v-model="model.clashes.enabled" :validation="$v.model.clashes.enabled" v-if="$isModerator" notes="Turn off clashing if this is an event that attendees can participate in at any time." />
			<app-input-select label="Ticketed" v-model="model.ticket" :allow-deselect="true" placeholder="Not ticketed" :validation="$v.model.ticket" v-if="$isModerator && references.tickets.length" :options="references.tickets" />
			<app-input-select label="Type" placeholder="Select type" v-model="model.type" :validation="$v.model.type" :options="typeOptions" />
			<app-input-suggest :disabled="isLocked" v-if="hasGame" label="Game" v-on:textchange="onGameNameChange" placeholder="Select game" image="image" v-model="model.game" api="bgg/game" :validation="$v.model.game" notes="You can skip this if you wish to enter a title directly but it will be harder for people to find your event." />
			<app-input-text :disabled="isLocked" label="Name" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" :maxlength="128" />
			<app-input-text label="Description" :autogrow="true" placeholder="Enter description..." v-model="model.description" :validation="$v.model.description" />
			<app-input-image label="Image" v-model="model.image" :validation="$v.model.image" notes="Image used must be relevant to the event and be appropriate for all ages." />
			<app-input-toggle :full-width="true" label="Min. Age" :slim="true" v-model="model.age" :validation="$v.model.age" :options="ageOptions" />
			<app-input-checklist :collapsed="!isNew" fullWidth="true" :singular="true" :columns="1" v-if="hasGame" label="Min. Experience" v-model="model.experience" :validation="$v.model.experience" :options="$constants.schedule.event.experienceName" :descriptions="$constants.schedule.event.experienceDescription" />
			<app-input-checklist :collapsed="!isNew" fullWidth="true" :singular="true" :columns="1" v-if="hasGame" label="Teacher Wanted" v-model="model.teacher" :validation="$v.model.teacher" :options="$constants.schedule.event.teacherName" :descriptions="$constants.schedule.event.teacherDescription" />
			<app-input-checklist v-if="references.lobbies.length" :max="lobbyLimit" label="Lobbies" notes="Select lobbies to help make your events easier to find." v-model="model.lobbies" :validation="$v.model.lobbies" :options="lobbyOptions" />


		</app-content-box>

		<app-content-box icon="buddies" title="Seats">

			<app-input-toggle label="Total seats" :slim="true" v-model="model.seats.total" :validation="$v.model.seats.total" :options="seatOptions" notes="The total number of players." />
			<app-input-text :disabled="isHostLocked" label="Enter number" v-model="model.seats.totalOther" :validation="$v.model.seats.totalOther" notes="Must be a number between 1 and 1000." v-if="model.seats.total === 1001" />
			<app-input-checklist :collapsed="!isNew" fullWidth="true" :disabled="isHostLocked" :singular="true" :columns="1" v-if="hasGame" label="Are you playing?" v-model="model.seats.host" :validation="$v.model.seats.host" :options="$constants.schedule.event.hostingName" :descriptions="$constants.schedule.event.hostingDescription" />
			<app-input-toggle label="Blocked seats" :slim="true" v-model="model.seats.blocked" :validation="$v.model.seats.blocked" :options="blockOptions" notes="The number of seats blocked for other people not on this system." v-if="model.seats.total <= 8 && hasGame" />
			<app-input-text :disabled="isHostLocked" label="Blocked seats" v-model="model.seats.blockedOther" :validation="$v.model.seats.blockedOther" notes="Must be a number between 0 and 1000." v-if="model.seats.total > 8 && model.seats.total !== 1002 && hasGame" />


		</app-content-box>

		<app-content-box icon="schedule" title="Time">

			<app-input-toggle label="Day" v-model="model.day" :validation="$v.model.day" :options="dayOptions" :fullWidth="true" />
			<app-input-select label="Time" :disabled="!model.day" v-model="model.time" :validation="$v.model.time" :options="timeOptions" placeholder="Select time" />
			<app-input-select label="Duration" :disabled="!model.day || !model.time" v-model="model.duration" :validation="$v.model.duration" :options="durationOptions" placeholder="Select duration" />
			
		</app-content-box>

		<app-content-box icon="location" title="Location" :loading="is.checking" :subtitle="locationSubtitle">
			
			<app-input-checklist :collapsed="!isNew" fullWidth="true" :singular="true" :columns="1" label="Do you know where you're playing?" v-model="model.location.skip" :validation="$v.model.location.skip" :options="$constants.schedule.event.locationName" :descriptions="$constants.schedule.event.locationDescription" />
			
			<div class="location-empty" v-if="!canLocate && !isSkipping">

				You must select the type, seats, day, time, and duration before location options will appear.

			</div>
			
			<div class="location-none" v-if="canLocate && !hasLocations && !isSkipping">

				Sorry, there are no official spaces available for your event. Try a shorter duration or another time, or assign later.

			</div>

			<div class="location-notice" v-if="isSkipping">

				We recommend you give at least 30 minutes notice of the location prior to the scheduled start time.

			</div>
		
			<template v-if="hasLocations && !isSkipping">

				<app-input-select placeholder="Select room" label="Room" :asString="true" v-model="model.location.room" :options="roomOptions" />
				<app-input-toggle v-if="model.location.room && spaceOptionsCount" label="Location" v-model="model.location.custom" :options="locationTypeOptions" />
				<app-input-select placeholder="Select table" v-if="model.location.room && !model.location.custom && spaceOptionsCount" label="Table" :asString="true" v-model="model.location.space" :options="spaceOptions" />
				<app-input-text v-if="model.location.room && (model.location.custom || !spaceOptionsCount)" label="Directions" maxlength="255" v-model="model.location.directions" placeholder="Enter directions" />

			</template>

		</app-content-box>

		<app-button text="Confirm" :disabled="!isValid" v-on:click="onSaveClick" :loading="is.saving" />

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			standalone: true,
			is: {
				checking: false
			},
			references: {
				tickets: [],
				types: [],
				days: [],
				times: [],
				rooms: [],
				clashing: [],
				spaces: [],
				durations: [],
				lobbies: []
			},
			hostOptions: {
				0: 'Yes',
				1: 'No'
			},
			locationTypeOptions: {
				0: 'Official table',
				1: 'Other space'
			},
			seatOptions: {
				1: 1,
				2: 2,
				3: 3,
				4: 4,
				5: 5,
				6: 6,
				7: 7,
				8: 8,
				1001: 'Other',
				1002: 'Unlimited'
			},
			model: {
				official: 0,
				type: 0,
				name: '',
				image: '',
				ticket: 0,
				teacher: 0,
				age: 0,
				game: 0,
				experience: 0,
				description: '',
				lobbies: [],
				clashes: {
					enabled: 1
				},
				seats: {
					host: 0,
					total: 0,
					totalOther: 0,
					blocked: 0,
					blockedOther: 0
				},
				day: 0,
				time: 0,
				duration: 0,
				location: {
					skip: 0,
					room: 0,
					space: 0,
					custom: 0,
					directions: ''
				}
			}
		}

	},

	validations: {
		model: {
			clashes: {},
			name: {
				required
			},
			type: {
				required
			},
			day: {
				required
			},
			time: {
				required
			},
			duration: {
				required
			},
			seats: {
				total: {
					required
				}
			},
			location: {
				skip: {},
				room: {},
				space: {},
				custom: {},
				directions: {}
			}
		}
	},

	computed: {

		selectedType: function() {

			return (this.model.type) ? this.$_.findWhere(this.references.types, {
				id: this.model.type
			}) : false

		},

		hasGame: function() {

			return (this.selectedType) ? this.selectedType.games.enabled : true

		},

		isHostLocked: function() {

			return this.item.seats.available === 0 && this.item.seats.host

		},

		isLocked: function() {

			return !this.item.proposal && ((this.item.seats.taken > this.item.seats.blocked + ((this.item.seats.host) ? 0 : 1)) && this.model.seats.total < 1001)

		},

		isSkipping: function() {

			return this.model.location.skip

		},

		locationSubtitle: function() {

			return (this.hasLocations) ? this.references.spaces.length + ' space' + ((this.references.spaces.length === 1) ? '' : 's') + ' available' : false

		},

		canLocate: function() {

			return this.model.day && this.model.time && this.model.duration && this.model.seats.total && this.model.type

		},

		hasLocations: function() {

			return this.canLocate && this.references.rooms.length

		},

		roomOptions: function() {

			var rooms = {}

			this.$_.each(this.references.rooms, function(room) {

				rooms[room.id] = room.name

			})

			return rooms

		},

		spaceOptions: function() {

			var spaces = {}

			this.$_.each(this.references.spaces, function(space) {

				if (space.room === this.model.location.room) spaces[space.id] = space.name

			}.bind(this))

			return spaces

		},

		locationInputs: function() {

			return {
				type: this.model.type,
				total: (this.model.seats.total === 1001) ? this.model.seats.total : this.model.seats.totalOther,
				day: this.model.day,
				time: this.model.time,
				duration: this.model.duration
			}

		},

		blockOptions: function() {

			var seats = {}
			var total = (this.model.seats.total > 8) ? this.model.seats.totalOther || 8 : this.model.seats.total || 8

			for (var i = 0; i <= total; i++) {

				seats[i] = i

			}

			return seats
		},

		typeOptions: function() {

			var options = {}

			this.$_.each(this.references.types, function(type) {

				options[type.id] = type.name

			})

			return options

		},

		lobbyOptions: function() {

			var options = {}

			this.$_.each(this.references.lobbies, function(type) {

				options[type.id] = type.name

			})

			return options

		},

		timeOptions: function() {

			var times = {}

			if (!this.model.day) return times
			
			this.$_.each(this.references.times, function(text, value) {

				if(this.references.clashing[this.model.day]) {

					if (!this.$_.contains(this.references.clashing[this.model.day], parseInt(value))) {

						times[value] = text

					}

				} else {

					times[value] = text

				}

			}.bind(this))

			return times

		},

		dayOptions: function() {

			var options = {}

			this.$_.each(this.references.days, function(type) {

				options[type.value] = type.text

			})

			return options

		},

		durationOptions: function() {

			var durations = {}

			if(!this.model.day || !this.model.time) return durations

			var time

			for(var i=5; i<=120; i+=5) {

				time = parseInt(this.model.time) + i

				if (this.timeOptions[time]) {

					durations[i] = this.references.durations[i]

				} else {

					durations[i] = this.references.durations[i]

					break

				}

			}

			return durations

		},

		ageOptions: function() {

			return this.$constants.schedule.ageShort

		},

		expOptions: function() {

			return this.$constants.schedule.experienceShort

		},

		expTooltips: function() {

			return this.$constants.schedule.experienceTooltip

		},

		gameChosen: function() {

			return this.model.game

		},

		scheduleDay: function() {

			return this.model.day

		},

		scheduleTime: function() {

			return this.model.time

		}

	},

	watch: {

		gameChosen: function() {

			if (this.is.initialised) {

				this.model.image = (this.model.game) ? 'https://img.geekgroup.app/api/games/avatar/' + this.model.game : ''

			}

		},

		scheduleDay: function() {

			if (this.is.initialised) {

				this.model.time = 0
				this.model.duration = 0 

			}

		},

		scheduleTime: function() {

			if (this.is.initialised) {

				this.model.duration = 0

			}

		},

		locationInputs: function() {

			if (this.is.initialised) {
				
				if (this.canLocate) this.onLocationCheck()

			}

		}

	},

	methods: {

		onModelUpdate: function() {

			var blockTotal = this.model.seats.blocked
			var seatsTotal = this.model.seats.total
			
			var blockTotalOther = 0

			if (seatsTotal > 8) {
				
				blockTotalOther = blockTotal

			}
			
			var seatsTotalOther = 0

			if (seatsTotal > 8) {
				
				seatsTotalOther = seatsTotal
				seatsTotal = 1001

			}
				
			if (seatsTotal === 0) {
				
				seatsTotal = 1002

			}

			this.model.seats.total = seatsTotal
			this.model.seats.totalOther = seatsTotalOther
			this.model.seats.blocked = blockTotal
			this.model.seats.blockedOther = blockTotalOther

			this.onLocationCheck()

		},

		onSaved: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event',
				params: {
					id: this.model.id
				}
			})

		},

		onGameNameChange: function(name) {

			if (this.is.initialised) {

				this.model.name = name

			}

		},

		onLocationCheck: async function() {

			this.is.checking = true

			var params = this.$util.copy(this.locationInputs)

			params.event = this.model.id

			await this.$api.get('convention/schedule/submit/location', params).then(function(json) {

				this.references.rooms = json.rooms 
				this.references.spaces = json.spaces

				if (this.model.location.room) {

					if (!this.$_.contains(this.$_.pluck(this.references.rooms, 'id'), this.model.location.room)) this.model.location.room = 0

				}

				if (this.model.location.space) {

					if (!this.$_.contains(this.$_.pluck(this.references.spaces, 'id'), this.model.location.space)) this.model.location.space = 0

				}

				this.is.checking = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.location-empty {
	font-size: 14px;
	line-height: 20px;
	margin-top: 10px;
	color: #707070;
	text-align: center;
	border: 1px solid #eee;
	padding: 20px;
	border-radius: 4px;
}

.location-none {
	font-size: 14px;
	line-height: 20px;
	margin-top: 10px;
	color: #fff;
	text-align: center;
	border: 1px solid #eee;
	padding: 20px;
	background-color: #f65d5d;
	border-radius: 4px;
}

.location-notice {
	font-size: 14px;
	line-height: 20px;
	margin-top: 10px;
	color: #fff;
	text-align: center;
	border: 1px solid #eee;
	padding: 20px;
	background-color: #4082d3;
	border-radius: 4px;
}

.is-device .location-empty,
.is-device .location-none,
.is-device .location-notice {
	margin-top: 20px;
	padding: 20px;
}

</style>